
//
// Google map
//

.gmaps, .gmaps-panaroma {
  height: 300px;
  width: 100%;
  background: $gray-100;
  border-radius: 10px;
}

.gmaps-explore {
  position: absolute;
  top: $header-height;
  left: $sidebar-width;
  right: 0;
  bottom: $footer-height;
  background: $gray-100;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: $white;
  font-size: 16px;
  line-height: 40px;
  background: $primary;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
  &.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid $primary;
  }
  &.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid $primary;
  }
  
}